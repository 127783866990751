import React, { useState } from 'react'
import './Dashboard.css'
import LogoIcon from '../../assets/icons/logo.svg'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useSubscription } from './useSubscription'
import { useJobs } from './useJobs'
import { getTime } from './utils'

function Dashboard() {
  const [isDark, setDark] = useState(false)
  const [isJob, setJob] = useState(false)
  const [jobUrl, setJobUrl] = useState('')
  const [jobTime, setJobTime] = useState('10MN')

  const { subscription, changeSubscription } = useSubscription()
  const { jobs, saveJob, deleteJob } = useJobs(subscription)

  const closeJobCard = () => {
    setJob(false)
  }

  const onJobsMutationSuccess = () => {
    closeJobCard()
    setJobTime('10MN')
    setJobUrl('')
  }

  const handleSave = () => {
    saveJob(jobUrl, jobTime, onJobsMutationSuccess).catch(console.error)
  }

  return (
    <div className="Dashboard">
      {isJob && (
        <div className="CardBackground">
          <div className={isDark ? 'JobCard dark' : 'JobCard white'}>
            <div className="CardHeader">
              <span>Add New Job</span>
              <svg
                onClick={() => closeJobCard()}
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill={isDark ? '#fff' : '#1b1f23'}
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
              </svg>
            </div>
            <div className="inputs">
              <input
                className={isDark ? 'field dark' : 'field white'}
                onChange={e => setJobUrl(e.target.value)}
                value={jobUrl}
                placeholder="Webhook url"
              />
              <div className="timer">
                <select
                  className={isDark ? 'field dark' : 'field white'}
                  onChange={e => setJobTime(e.target.value)}
                  value={jobTime}
                  placeholder="Run every"
                >
                  <option value="10MN">execute every 10 minutes</option>
                  <option value="30MN">execute every 30 minutes</option>
                  <option value="1HR">execute every hour</option>
                  <option value="6HR">execute every 6 hours</option>
                  <option value="8HR">execute every 8 hours</option>
                  <option value="12HR">execute every 12 hours</option>
                  <option value="1DY">execute every day</option>
                  <option value="2DY">execute every 2 days</option>
                  <option value="5DY">execute every 5 days</option>
                  <option value="7DY">execute every 7 days</option>
                  <option value="14DY">execute every 14 days</option>
                  <option value="30DY">execute every 30 days</option>
                </select>
              </div>
            </div>
            <div className="ColumnBottom">
              <div
                className={isDark ? 'Button dark' : 'Button white'}
                onClick={handleSave}
              >
                SAVE
              </div>
              <div
                className={isDark ? 'Button dark' : 'Button white'}
                onClick={() => closeJobCard()}
              >
                CANCEL
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={isDark ? 'Constructor dark' : 'Constructor white'}>
        <div className="Header">
          <div className="SubHeader">
            <img className="logo" alt="Logo" src={LogoIcon} />
            <span>
              <span className="yellow">Cronjobs</span>.expert
            </span>
            <p
              className="yellow"
              onClick={() =>
                window.open('https://www.welcomenocode.com/', '_blank')
              }
            >
              Provided by WNC
            </p>
          </div>
          <div className="HeaderButtons" style={{ marginLeft: '105px' }}>
            <button
              onClick={() => changeSubscription()}
              className={isDark ? 'Button dark' : 'Button white'}
            >
              Change subscription plan
            </button>
            <button
              onClick={() => setJob(true)}
              className={isDark ? 'Button dark' : 'Button white'}
            >
              Create new job
            </button>
          </div>
        </div>
        <div className="Body">
          <div className="JobList">
            {jobs && jobs.length > 0
              ? jobs.map(job => {
                  const handleDelete = () => {
                    deleteJob(job.id, onJobsMutationSuccess)
                  }

                  return (
                    <div className="JobRow" key={job.id}>
                      <div className="SubJobRow">
                        <div className={isDark ? 'JobTime-d' : 'JobTime-w'}>
                          GET
                        </div>
                        <input
                          className={isDark ? 'JobUrl-d' : 'JobUrl-w'}
                          value={job.url}
                        />
                        <div className={isDark ? 'JobTime-d' : 'JobTime-w'}>
                          {getTime(job.time)}
                        </div>
                        <button
                          onClick={handleDelete}
                          className={isDark ? 'Button dark' : 'Button white'}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  )
                })
              : ''}
          </div>
        </div>
      </div>
      <div className={isDark ? 'RightMenu dark' : 'RightMenu white'}>
        <div className={isDark ? 'Theme white' : 'Theme dark'}>
          <div
            className={isDark ? 'Sun white' : 'Sun dark'}
            onClick={() => setDark(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill={isDark ? '#1b1f23' : '#fff'}
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M20 8.69V4h-4.69L12 .69 8.69 4H4v4.69L.69 12 4 15.31V20h4.69L12 23.31 15.31 20H20v-4.69L23.31 12 20 8.69zM12 18c-3.31 0-6-2.69-6-6s2.69-6 6-6 6 2.69 6 6-2.69 6-6 6zm0-10c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4z" />
            </svg>
          </div>
          <div
            className={isDark ? 'Moon dark' : 'Moon white'}
            onClick={() => setDark(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              enable-background="new 0 0 24 24"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill={isDark ? '#fff' : '#1b1f23'}
            >
              <g>
                <path d="M0,0h24v24H0V0z" fill="none" />
              </g>
              <g>
                <g>
                  <g>
                    <path d="M14,2c1.82,0,3.53,0.5,5,1.35C16.01,5.08,14,8.3,14,12s2.01,6.92,5,8.65C17.53,21.5,15.82,22,14,22C8.48,22,4,17.52,4,12 S8.48,2,14,2z" />
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </div>
      </div>
      <ToastContainer theme={isDark ? 'dark' : 'light'} position="top-right" />
    </div>
  )
}

export default Dashboard
