export function isValidHttpUrl(string) {
  let url

  try {
    url = new URL(string)
  } catch (_) {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}

export function getTime(time) {
  if (time === '10MN') return 'execute every 10 minutes'
  if (time === '30MN') return 'execute every 30 minutes'
  if (time === '1HR') return 'execute every hour'
  if (time === '6HR') return 'execute every 6 hours'
  if (time === '8HR') return 'execute every 8 hours'
  if (time === '12HR') return 'execute every 12 hours'
  if (time === '1DY') return 'execute every day'
  if (time === '2DY') return 'execute every 2 days'
  if (time === '5DY') return 'execute every 5 days'
  if (time === '7DY') return 'execute every 7 days'
  if (time === '14DY') return 'execute every 14 days'
  if (time === '30DY') return 'execute every 30 days'
  return ''
}
