import { useCallback, useEffect, useRef, useState } from 'react'
import { useAuthContext } from '../../auth/AuthProvider'

export const useSubscription = () => {
  const { token } = useAuthContext()
  const [subscription, setSubscription] = useState(null)
  const isVerifyingRef = useRef(false)

  const verifyAndSetSubscription = useCallback(async () => {
    isVerifyingRef.current = true
    const res = await fetch(
      `https://t8zax8nfj1.execute-api.eu-central-1.amazonaws.com/production/api/v1/subscriptions/cronjobsexpert/app?token=${token}`
    )
    const ex_sub = await res.json()

    if (ex_sub && ex_sub.data) {
      setSubscription(ex_sub.data)
    } else {
      setSubscription({
        status: 'NONE',
      })
    }

    isVerifyingRef.current = false
  }, [token])

  useEffect(() => {
    if (isVerifyingRef.current) return

    verifyAndSetSubscription().catch(console.error)
  }, [verifyAndSetSubscription])

  const changeSubscription = () => {
    window.open(
      `https://bank.welcomenocode.com?token=${token}&app=cronjobsexpert`
    )
  }

  return {
    subscription,
    changeSubscription,
  }
}
