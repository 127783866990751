import { useCallback, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { isValidHttpUrl } from './utils'
import { useAuthContext } from '../../auth/AuthProvider'

export const useJobs = subscription => {
  const [jobs, setJobs] = useState([])
  const isLoadingRef = useRef(false)

  const { token } = useAuthContext()

  const loadJobs = useCallback(async () => {
    isLoadingRef.current = true

    const token = localStorage.getItem('token')
    const jobsRes = await fetch(
      'https://4c8gdtxmpc.execute-api.eu-central-1.amazonaws.com/production/api/v1/jobs/my?token=' +
        token,
      { method: 'GET' }
    )
    const jobsData = await jobsRes.json()
    setJobs(jobsData.data)
    isLoadingRef.current = false
  }, [token])

  const saveJob = async (jobUrl, jobTime, onSuccess) => {
    let limit = 1

    if (subscription && subscription.status === 'APPROVAL_PENDING') {
      return toast.warn('Your subscription is waiting for approval!')
    }

    if (
      subscription &&
      subscription.plan_id &&
      subscription.status === 'ACTIVE'
    ) {
      if (subscription.plan_id === 'cronjobsexpertpro') limit = 60
      if (subscription.plan_id === 'cronjobsexpertbasic') limit = 19
      if (subscription.plan_id === 'cronjobsexpertstartup') limit = 6
    }

    if (jobs.length < limit) {
      if (isValidHttpUrl(jobUrl)) {
        const res = await fetch(
          'https://4c8gdtxmpc.execute-api.eu-central-1.amazonaws.com/production/api/v1/jobs/new?token=' +
            token,
          {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              url: jobUrl,
              job_type: 'GET',
              time: jobTime,
            }),
          }
        )

        const data = await res.json()

        if (data.success) {
          // works
          await loadJobs()
          onSuccess()
          toast('Job created!')
        } else {
          // unwork
          toast.warn('Job is not created!')
        }
      } else {
        toast.warn('Sorry,  but url is wrong!')
      }
    } else {
      toast.warn(
        'You cannot create more then one job. Please upgrade the plan! '
      )
    }
  }

  const deleteJob = async (id, onSuccess) => {
    const res = await fetch(
      'https://4c8gdtxmpc.execute-api.eu-central-1.amazonaws.com/production/api/v1/jobs/' +
        id +
        '?token=' +
        token,
      {
        method: 'DELETE',
      }
    )

    const data = await res.json()

    if (data.success) {
      // works
      await loadJobs()
      onSuccess()
      toast('Job deleted!')
    } else {
      // unwork
      toast.warn('Job is not deleted!')
    }
  }

  useEffect(() => {
    if (isLoadingRef.current) return

    loadJobs().catch(console.error)
  }, [loadJobs])

  return { jobs, saveJob, deleteJob }
}
