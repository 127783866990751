import React, { useState } from 'react'
import './Landing.css'
import time from '../../assets/images/time.jpg'
import money from '../../assets/images/money.jpg'
import Cam_Burley from '../../assets/images/cam_burley.avif'
import Farhan_Kabir from '../../assets/images/Farhan_Kabir.avif'
import Shubham_Pratap_Singh from '../../assets/images/Shubham_Pratap_Singh.avif'

function Landing() {
    const [data, setData] = useState({})

    const openDashboard = () => {
        window.location.pathname = '/dashboard';
    }

    const scrollTo = (id) => {
        document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <div className='Landing'>
            <div className='Menu'>
                <div className='MenuItem' onClick={() => scrollTo('about')}>About</div>
                <div className='MenuItem' onClick={() => scrollTo('prices')}>Prices</div>
                <div className='MenuItem' onClick={() => scrollTo('feedbacks')}>Feedbacks</div>
            </div>
            <div className='Header'>
                <div className='HeaderContent'>
                    <h1 className='Title'>Cron Jobs Expert</h1>
                    <span className='Subtitle'>Our new tool makes webhook scheduling easy and hassle-free. Say goodbye to unreliable cron setups and unstable performance. Try it out today and experience the power of seamless webhook scheduling.</span>
                    <button className='Button-Get-Started' onClick={() => openDashboard()}>Get Started</button>
                </div>
                <img className='Time' src={time}/>
            </div>
            <div className='About' id="about">
                <div className='AboutTitle'>
                    About Cron Jobs Expert
                </div>
                <div className='AboutText'>
                    Introducing the solution to all your webhook scheduling woes - our new tool! Say goodbye to unreliable cron setups and hello to seamless webhook scheduling.
                </div>
                <div className='AboutText'>
                    With our easy-to-use interface, you can schedule all your webhooks in a snap, without having to worry about server configurations or unstable performance.
                </div>
                <div className='AboutText'>
                    Whether you're scheduling webhooks for your business or personal projects, our tool is designed to make your life easier.
                </div>
                <div className='AboutText'>
                    So what are you waiting for?
                </div>
                <div className='AboutText'>
                    Try it out today and experience the power of hassle-free webhook scheduling!
                </div>
            </div>
            <div className='Prices' id="prices">
                <div className='PricesTitle'>Prices</div>
                <div className='PriceContent'>
                    <div className='PricesText'>Introducing our webhook scheduling tool - get your first scheduler for free! After that, each two additional schedulers will cost just +2 dollars. With our affordable pricing and user-friendly interface, you can easily schedule all your webhooks without breaking the bank. Try it out today and experience the power of hassle-free webhook scheduling!</div>
                    <img className='MoneyIcon' src={money}/>
                </div>
            </div>
            <div className='Feedbacks' id="feedbacks">
                <div className='FeedbacksTitle'>Feedbacks</div>
                <div className='FeedbacksContent'>
                    <div className='FeedbackItem'>
                        <img className='CommentImg' src={Cam_Burley}/>
                        <div className='Comment'>
                            <div className='CommentOwner'>Cam Burley</div>
                            <div className='CommentText'>Genius! No more managing cron-jobs— such a clever execution. Can't wait to use it</div>
                        </div>
                    </div>
                    <div className='FeedbackItem'>
                        <img className='CommentImg' src={Farhan_Kabir}/>
                        <div className='Comment'>
                            <div className='CommentOwner'>Farhan Kabir</div>
                            <div className='CommentText'>Wow! This looks so amazing!</div>
                        </div>
                    </div>
                    <div className='FeedbackItem'>
                        <img className='CommentImg' src={Shubham_Pratap_Singh}/>
                        <div className='Comment'>
                            <div className='CommentOwner'>Shubham Pratap Singh</div>
                            <div className='CommentText'>Congratulations on the launch🎉🎉</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='Footer'>
                <div className='FooterText'>© 2022-{new Date().getFullYear()} Cron Jobs Expert. All rights reserved.</div>
                <div className='Company'>Provided by <a href='https://www.welcomenocode.com'>Welcomenocode</a></div>
                <div className='Links'>
                    <a href='https://www.welcomenocode.com/privacy-policy?app=cronjobsexpert'>Privacy Policy</a>
                    <a href='https://www.welcomenocode.com/refund-policy?app=cronjobsexpert'>Refund Policy</a>
                    <a href='https://www.welcomenocode.com/terms-and-conditions?app=cronjobsexpert'>Terms & Conditions</a>
                </div>
            </div>
        </div>
    )
}

export default Landing
  